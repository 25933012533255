a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

main {
  max-width: 100%;
}

body {
  font-family: 'Averia Serif Libre', serif !important;
}


.Dis_Scroll {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.Dis_Scroll::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}


@media (max-width: 576px) {
  .Dis_Scroll .d-flex {
    flex-direction: column;
    align-items: flex-start;
  }

  .Dis_Scroll button {
    width: 100%;
  }

  .css-19kzrtu {
    padding: 5px !important;
  }

  .order_room .container {
    padding: 0 !important;
  }
}

.card {
  border-color: #ddd;
  transition: transform 0.2s ease;
}

.card:hover {
  transform: translateY(-5px);
}